
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthu_j9sY_45ZFtosz5lKAq7FhPU5egEtha35HBuzqs0UnoMMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93TXkXOqYZ_TmNZbqKaD7CufzNzPRgwLRtJkjG0CSvubAMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue?macro=true";
import { default as guidesVxUIJjh7SNi_45vYuz_45i_45TDNn0X0B42FUnj2uckC6eq7UMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue?macro=true";
import { default as port_45parkingz_45Otk_45f4jkbJLjNUFHlAo0fUu4DrGA0q_mzAKSa3NaQMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue?macro=true";
import { default as airport_45parking2ySYpeGKyL9IignYr3uRSOvVF3qTQc8WmEi07EFgiksMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue?macro=true";
import { default as parking_45couponsvG5hoSny42NYOeCwa8SIABI6uBIJpv1k4ROV6a_tJv0Meta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue?macro=true";
import { default as _91result_93EmHBqV8ivGPNYk8qCji14Xq3QJ0paHTenulkcTnig1kMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue?macro=true";
import { default as airport_45shuttlesT7PY2K1Ve2Y7KVpIBNV0OhpkBWtitnPBYUr3ZR9sMy8Meta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93alChXrSjlZkJB9nesSEyaeZ6g7tI275mqKq1SYRYFvwMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as off_45airport_45parkingda766B2yzDBaKYd_457ScnYNUnKXxqPjfHJdPmo7Nh5_454Meta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93_45azqRdqqsllCt6vLLXs6yGBPF_mCRVb6diAnADA_JgEMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as port_45hotels_45with_45parkingccSQQvyK6nZXbehPqrV8I5ukWycAJv9HPmsbm3XcgZAMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as airport_45hotels_45with_45parkingBofY0ApPT4cdjXllbyyGuxB0BBhJX4FtnXYSbKQSf9UMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93MXb_45V3OXsIFo7_YetOkUtSQuCdvFVnr9bQ5ef_45h7eKMMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0Meta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0 } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue")
  },
  {
    name: "IATA-guides___en",
    path: "/:IATA()/guides",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-parking-coupons___en",
    path: "/:IATA()/parking-coupons",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue")
  },
  {
    name: "flight-status-result___en",
    path: "/flight-status/:result()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue")
  },
  {
    name: "IATA-airport-shuttles___en",
    path: "/:IATA()/airport-shuttles",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-off-airport-parking___en",
    path: "/:IATA()/off-airport-parking",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "Guides___en",
    path: "/:IATA([a-z]{3})",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0
  }
]